<template>
    <div class="information_container" :class="{information_disabled:data.openid}">
        <itemScroll ref="itemScroll">
        <div>
        <div class="information_header">
            <div class="header_img">
                <img :src="require('@/assets/images/header.png')"/>
            </div>
        </div>
        <div class="information_body">
            <div class="information_text" v-if="complete && !data.openid">绑定</div>
            <!-- 基本信息 -->
            <div class="information_information_block">
                <div class="information_information_block_key">基本信息</div>
                <div class="information_information_block_value">
                    <div class="information_information_input">
                        <div class="global_container_mask" v-if="data.openid"></div>
                        <div class="information_information_input_key">姓名</div>
                        <div class="information_information_input_value global_text_input">
                            <el-input v-model="data.name" @input="changeInput" placeholder="请输入姓名" />
                            <!-- <el-select
                                v-model="data.name"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请输入姓名"
                                :remote-method="loadDoctor"
                            >
                                <el-option
                                v-for="item in config.doctors"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                >
                                </el-option>
                            </el-select> -->
                        </div>
                    </div>
                    <div class="information_information_input">
                        <div class="global_container_mask" v-if="data.openid"></div>
                        <div class="information_information_input_key">省份</div>
                        <div class="information_information_input_value global_text_input">
                            <el-select v-model="data.province" @change="changeProvince" placeholder="请选择">
                                <el-option
                                v-for="(item,index) in getProvinceList"
                                :key="index"
                                :label="item"
                                :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="information_information_input">
                        <div class="global_container_mask" v-if="data.openid"></div>
                        <div class="information_information_input_key">城市</div>
                        <div class="information_information_input_value global_text_input">
                            <el-select v-model="data.city" @change="changeCity" placeholder="请选择">
                                <el-option
                                v-for="(item,index) in getCityList"
                                :key="index"
                                :label="item"
                                :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 医院 -->
            <div class="information_information_block">
                <div class="information_information_block_key">医院</div>
                <div class="information_information_block_value">
                    <div class="information_information_input">
                        <div class="global_container_mask" v-if="data.openid"></div>
                        <div class="information_information_input_key">医院</div>
                        <div class="information_information_input_value global_text_input">
                            <el-select v-model="data.hospital" @change="changeHospital" placeholder="请选择">
                                <el-option
                                v-for="(item,index) in getHospitalList"
                                :key="index"
                                :label="item"
                                :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="information_information_input">
                        <div class="global_container_mask" v-if="data.openid"></div>
                        <div class="information_information_input_key">科室</div>
                        <div class="information_information_input_value global_text_input">
                            <el-select v-model="data.department" placeholder="请选择">
                                <el-option
                                v-for="(item,index) in getDepartmentList"
                                :key="index"
                                :label="item"
                                :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
            
        <div class="information_button" v-if="complete && !data.openid">
            <el-button type="primary" @click="doCommit" size="mini">确认绑定</el-button>
        </div>
        </div>
    </div>
    </itemScroll>
    </div>
</template>
<script>
// import citys from "@/common/citys";
import {ElSelect,ElOption,ElButton,ElInput} from 'element-plus';
import itemScroll from "@/components/unit/itemScroll"
export default {
  name:"information",
    data() {
        return {
            citys:[],
            data:{
                name:"",
                province:"",
                city: "",
                hospital:"",
                department:""
            },
            config:{
                doctors:[],
                provinces:[],
                citys:[],
                hospitals:[],
                departments:[],
                dataItem:{}
            },
            requestTimer:{
                isRun:false,
                time:null,
                maxCount:1000, // 停止输入多少毫秒开始请求
                startTime:0, // 当前输入时间
                frameRate:100 // 多少毫秒执行一次
            },
            loading:false,
            complete:false
        }
    },
    created() {
    },
    components:{
        itemScroll,
        ElSelect,
        ElOption,
        ElInput,
        ElButton
    },
    computed:{
        getProvinceList(){
            let list = Object.keys(this.config.dataItem);
            return  this.$tools.sortChinese(list);
        },
        getCityList(){
            let list = Object.keys(this.config.dataItem[this.data.province] || {});
            return  this.$tools.sortChinese(list);
        },
        getHospitalList(){
            let list = Object.keys(this.config.dataItem[this.data.province]?.[this.data.city] || {});
            return  this.$tools.sortChinese(list);
        },
        getDepartmentList(){
            let list = this.config.dataItem[this.data.province]?.[this.data.city]?.[this.data.hospital] || [];
            return  this.$tools.sortChinese(list);
        }
    },
    mounted(){
        // this.initProvince();
        this.initCitys()
        setTimeout(()=>{
            this.$nextTick(()=>{
                this.$refs.itemScroll && this.$refs.itemScroll.initScroll();
            })
        },1000)
    },
    methods: {
        async initCitys (){
            // let citys = await this.$tools.getCityConfig()
            // this.citys = this.$tools.sortChinese(citys,"name")
            this.loadData()
        },
        loadData(){
            this.$axios.get(this.$urls.doctor.doctorInfo).then(res=>{
                this.complete = true;
                if(res.data && res.data.openid){
                    this.data = {...this.data,...res.data};
                    document.getElementsByTagName("title")[0].innerHTML = "我的信息"
                }else{
                    document.getElementsByTagName("title")[0].innerHTML = "绑定"
                }
                // this.initProvince();

            }).catch(err=>{})
        },
        loadDoctor(){
            let url = this.$tools.getURL(this.$urls.doctor.register,{
                name:this.data.name
            })
            this.$axios.get(url).then((res)=>{
                this.initInfoData(res.data);
            }).catch((err)=>{
                console.log(err)
            })
        },
        loadHospitals(){
            let url = this.$tools.getURL(this.$urls.doctor.hospitals,this.data)
            this.$axios.get(url).then(res=>{
                this.config.hospitals = this.$tools.sortChinese(res.data,"hospital");
            }).catch(err=>{})
        },
        loadDepartments(){
            let url = this.$tools.getURL(this.$urls.doctor.departments,this.data)
            this.$axios.get(url).then(res=>{
                this.config.departments = this.$tools.sortChinese(res.data,"department");
            }).catch(err=>{})
        },
        initBaseData(){
            this.data = {
                name:this.data.name,
                province:"",
                city: "",
                hospital:"",
                department:""
            }
        },
        initInfoData(data){
            if(data.length == 0) {
                this.config.dataItem = {}
                this.initBaseData()
                return;
            }
            // 数据格式
            //     {
            //     "省份"：{
            //         "城市":{
            //             "医院":["科室"]
            //         }
            //     }
            // }
            let dataItem = {}
            data.forEach(val=>{
                dataItem[val.province] =  dataItem[val.province] || {}
                dataItem[val.province][val.city] =  dataItem[val.province][val.city] || {}
                dataItem[val.province][val.city][val.hospital] =  dataItem[val.province][val.city][val.hospital] || []
                if(!dataItem[val.province][val.city][val.hospital].includes(val.department)){
                    dataItem[val.province][val.city][val.hospital].push(val.department)
                }
            })
            this.config.dataItem = dataItem
            if(data.length == 1){
                this.data = {
                    name:data[0].name,
                    province:data[0].province,
                    city:data[0].city,
                    hospital:data[0].hospital,
                    department:data[0].department
                }
            }
        },
        // initProvince() {
        //     this.config.provinces = this.citys.map(x=>x.name);
        //     if(this.data.city) this.changeProvince()
        // },
        initRequestTimer(){
            if(this.requestTimer.time){
                clearTimeout(this.requestTimer.time);
                this.requestTimer.time = null;
            }
            if(this.requestTimer.startTime == 0){
                this.requestTimer.isRun = false;
                return;
            }
            if(Date.now() - this.requestTimer.startTime > this.requestTimer.maxCount){
                this.loadDoctor()
                this.requestTimer.startTime = 0;
            }
            this.requestTimer.isRun = true;
            setTimeout(()=>{ this.initRequestTimer() },this.requestTimer.frameRate)
        },
        changeProvince(value) {
            this.data.city = "";
            this.data.hospital = ""
            this.data.department = ""
            // let index = this.config.provinces.indexOf(this.data.province);
            // if(value) {
            //     this.data.city = "";
            //     this.data.hospital = ""
            //     this.data.department = ""
            // }
            // this.config.citys = this.$tools.sortChinese(this.citys[index].sub.map(x=>x.name))
        },
        changeCity(){
            this.data.hospital = "";
            this.data.department = "";
            // this.config.hospitals = [];
            // this.config.departments = [];
            // this.loadHospitals();
        },
        changeHospital(){
            // this.config.departments = [];
            this.data.department = "";
            // this.loadDepartments()
        },
        verifyData(){
            if(!this.data.name){
                this.$tips.error({text:"请输入姓名"})
                return false;
            }
            if(!this.data.province){
                this.$tips.error({text:"请选择省份"})
                return false;
            }
            if(!this.data.city){
                this.$tips.error({text:"请选择城市"})
                return false;
            }
            if(!this.data.hospital){
                this.$tips.error({text:"请选择医院"})
                return false;
            }
            if(!this.data.department){
                this.$tips.error({text:"请选择科室"})
                return false;
            }
            return true;
        },
        changeInput(){
            this.requestTimer.startTime = Date.now();
            if(!this.requestTimer.isRun){
                this.initRequestTimer()
            }
        },
        doCommit(){
            if(this.loading || !this.verifyData()) return;
            this.loading = true;
            this.$axios.post(this.$urls.doctor.register,this.data).then(res=>{
                this.$tips.success({text:"绑定成功"})
                this.$router.push({
                    path:"/myMeeting"
                })
            }).catch(err=>{
                setTimeout(()=>{ this.loading = false },1000)
            })
        }
    },
}
</script>

<style>
.information_container{
    width: 100%;
    height: 100%;
    background: #fff;
    padding:0 5.33vw;
    overflow:hidden;
}
.information_header{
    width: 100%;
    height: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header_img{
    width: 42vw;
}
.header_img img{
    width: 100%;
    height: auto;
}
.information_body{
    width: 100%;
}
.information_text{
    width: 100%;
    display: flex;
    margin-bottom: 5vw;
    justify-content: center;
    align-items: center;
    color: blue;
    font-size: 5.33vw;
    font-weight: 400;
}
.information_information_block{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 7.2vw;
}
.information_information_block_key{
    width: 26vw;
    height: 7.5vw;
    background: url("../../../assets/images/icon/login.png");
    background-size: 100%;
    color:#fff;
    font-weight: 400;
    font-size: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vw;
}
.information_information_block_value{
    border-top: 1px #b8b8b8 solid;
}
.information_information_input{
    height: 13.4vw;
    display: flex;
    align-items: center;
    padding-top: 3.8vw;
    position: relative;
}
.information_information_input_key{
    width: 18vw;
    height: 100%;
    margin-right: 2.8vw;
    background: #d6d6d6;
    border-radius: 0.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.information_information_input_value{
    flex: 1 0 0;
    border-bottom:1px solid #e6e6e6;
    padding-right:0;

    
}
.information_button{
    display: flex;
    justify-content: center;
}
.information_button .el-button{
    border:none;
    width: 73vw;
    height: 10vw;
    border-radius: 1vw;
    background: url("../../../assets/images/icon/button.png");
    background-size: 100%;
}
 .information_button .el-button:hover{
    background: url("../../../assets/images/icon/button.png");
    background-size: 100%;
 }
 .information_disabled .el-input__suffix{
     display: none;
 }
</style>
